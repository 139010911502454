import MainLayout from '@/components/layouts/main-layout';
import Link from 'next/link';
import {FiArrowLeft} from 'react-icons/fi';
import {FormattedMessage} from 'react-intl';
import type {FC} from 'react';

const PageNotFound: FC = () => (
  <MainLayout metadata={{title: 'Page not found - Soubul'}}>
    <div className="flex flex-1 items-center justify-center">
      <div className="text-center">
        <h1 className="text-3xl my-12">
          <FormattedMessage id="words.page_not_found" />
        </h1>
        <Link href="/" passHref>
          <a className="text-light-400 flex items-center my-12">
            <FiArrowLeft className="mx-1" />
            <span className="mx-1">
              <FormattedMessage id="words.back_to_the_home_page" />
            </span>
          </a>
        </Link>
      </div>
    </div>
  </MainLayout>
);

export default PageNotFound;
